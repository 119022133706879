<template>
    <div class="logistic-filter-bar">
        <div class="menu">
            <vs-button
                :type="isModeSelected('receive') ? 'filled' : 'border'"
                class="receive-tab-btn"
                @click="onModeChange('receive')"
                data-cy="receive-tab"
            >À réceptionner</vs-button>
            <vs-button
                :type="isModeSelected('received') ? 'filled' : 'border'"
                class="received-tab-btn"
                @click="onModeChange('received')"
                data-cy="received-tab"
            >Réceptionnées</vs-button>
            <div class="blank"></div>
            <div class="shipping-late">
                <div
                    class="bullet"
                    v-if="lateCount > 0"
                >{{ lateCount }}</div>
                <div class="label">{{ getLateLegend() }}</div>
                <hx-switch
                    class="late-switch"
                    v-model="late"
                    data-cy="late-switch"
                >
                    <span slot="on">Oui</span>
                </hx-switch>
            </div>
        </div>
        <div class="actions">
            <div class="title">Rechercher par :</div>
            <div class="filters">
                <div class="filter order-search">
                    <hx-input
                        class="order-input"
                        placeholder="N° de commande"
                        :clearButton="true"
                        v-model="order"
                        @clear="onOrderClear"
                        data-cy="order-field"
                    />
                </div>
                <div class="filter resident-search">
                    <v-select
                        class="resident-select"
                        autocomplete
                        placeholder="Résidents"
                        :options="residents"
                        label="text"
                        v-model="resident"
                        @search="onResidentSearch"
                        :filterable="false"
                        data-cy="resident-select"
                    >
                        <template #no-options="{ search, searching, loading }">
                            Aucun résident trouvé.
                        </template>
                    </v-select>
                </div>
                <div class="filter vendor-search">
                    <v-select
                        class="vendor-select"
                        autocomplete
                        placeholder="Prestataires"
                        :options="vendors"
                        label="text"
                        v-model="vendor"
                        @search="onVendorSearch"
                        :filterable="false"
                        data-cy="vendor-select"
                    >
                        <template #no-options="{ search, searching, loading }">
                            Aucun prestataire trouvé.
                        </template>
                    </v-select>
                </div>
                <PeriodPicker
                    class="filter date-picker"
                    :placeholder="getPeriodPlaceholder()"
                    :language="datePickerLocale"
                    dateStartLegend="Date de début :"
                    dateEndLegend="Date de fin :"
                    :legendPredicate="legendPredicate"
                    :dateStart="period ? period.dateStart : null"
                    :dateEnd="period ? period.dateEnd : null"
                    @change="onPeriodChange"
                    data-cy="date-picker"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import {
    fr
} from 'vuejs-datepicker/dist/locale'
import vSelect from 'vue-select'

import Http from '@/http'
import {
    getRequestUrl
} from '@/modules/url'
import {
    formatLocalDate
} from '@/modules/date'

import Formats from '@/constants/formats'

import PeriodPicker from '@/components/PeriodPicker'

export default {
    name: 'LogisticFilterBar',
    components: {
        PeriodPicker,
        vSelect
    },
    props: {
        filters: {
            type: Object,
            required: true
        },
        lateCount: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            datePickerLocale: fr,
            mode: 'receive',
            late: false,
            order: null,
            resident: null,
            residents: [],
            vendor: null,
            vendors: [],
            period: null
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getLateLegend () {
            if (this.mode == 'receive') {
                return `Inclure les livraisons en retard`
            }
            return `Inclure les incidents ouverts`
        },
        getPeriodPlaceholder () {
            if (this.mode == 'receive') {
                return `Date de livraison`
            }
            return `Date de réception`
        },
        isModeSelected (mode) {
            return this.mode == mode
        },
        legendPredicate (dateStart, dateEnd) {
            var legend = ''
            var dateStartText = dateStart ? formatLocalDate(dateStart, Formats.SimpleDate) : ''
            var dateEndText = dateEnd ? formatLocalDate(dateEnd, Formats.SimpleDate) : ''
            if (dateStart && dateEnd) {
                legend = `Du ${dateStartText} au ${dateEndText}`
            }
            else if (dateStart) {
                legend = dateStartText
            }
            else if (dateEnd) {
                legend = `Avant le ${dateEndText}`
            }
            return legend
        },
        onModeChange (mode) {
            this.mode = mode
            this.late = this.mode == 'received'
            this.order = null
            this.resident = null
            this.vendor = null
            this.period = null
            this.sendChange('mode')
        },
        onPeriodChange (value) {
            //console.log('dateStart', value.dateStart)
            //console.log('dateEnd', value.dateEnd)
            this.period = value
            this.sendChange('period')
        },
        sendChange (name) {
            const {
                mode,
                late,
                order,
                resident,
                vendor,
                period
            } = this
            this.$emit('change', {
                name,
                filters: {
                    mode,
                    late,
                    order,
                    resident: _.get(resident, 'value', null),
                    vendor: _.get(vendor, 'value', null),
                    period
                }
            })
        },
        onOrderClear () {
            this.order = null
        },
        onResidentSearch (search) {
            //console.log('resident-search', search)
            if (search) {
                this.searchResidents(search)
            }
        },
        searchResidents (search) {
            var params = {
                institutionId: this.getInstitutionId(),
                max: 50
            }
            if (search) {
                params.search = search
            }
            //console.log('params', params)
            const userId = this.getUserId()
            const requestUrl = getRequestUrl(params)
            Http.apis.ehpad.get(`/users/${userId}/residents/autocomplete${requestUrl}`).then((response) => {
                const data = response.data || {}
                //console.log('residents:data', data)
                const residents = data.residents || []
                this.residents = _.map(residents, (resident, index) => {
                    const firstName = _.get(resident, 'firstName', '-')
                    const lastName = _.get(resident, 'lastName', '-')
                    return {
                        text: `${lastName} ${firstName}`,
                        value: resident.id || index
                    }
                })
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        onVendorSearch (search) {
            //console.log('vendor-search', search)
            if (search && search.length > 1) {
                this.searchVendors(search)
            }
        },
        searchVendors (search) {
            var params = {
                institutionId: this.getInstitutionId(),
                max: 50
            }
            if (search) {
                params.search = search
            }
            //console.log('vendor:params', params)
            const requestUrl = getRequestUrl(params)
            Http.apis.ehpad.get(`/shops/autocomplete${requestUrl}`).then((response) => {
                const data = response.data || {}
                //console.log('vendors:data', data)
                const shops = data.shops || []
                this.vendors = _.map(shops, (shop) => {
                    return {
                        text: shop.shopName,
                        value: shop.shopId
                    }
                })
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: null,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    watch: {
        late (val) {
            this.sendChange('late')
        },
        order (val) {
            if (!val || val.length > 2) {
                this.sendChange('order')
            }
        },
        resident (val) {
            const value = _.get(val, 'value', null)
            if (!value) {
                this.searchResidents()
            }
            this.sendChange('resident')
        },
        vendor (val) {
            const value = _.get(val, 'value', null)
            if (!value) {
                this.searchVendors()
            }
            this.sendChange('vendor')
        }
    },
    mounted () {
        this.searchResidents()
        this.searchVendors()
    }
}
</script>

<style lang="scss">

.logistic-filter-bar {
    padding: 20px 20px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;

    .menu {
        display: flex;
        align-items: center;

        .receive-tab-btn {
            width: 209px;
        }
        .received-tab-btn {
            width: 209px;
            margin: 0px 0px 0px 15px;
        }
        .blank {
            flex-grow: 1;
        }
        .shipping-late {
            display: flex;
            align-items: center;

            .bullet {
                padding: 5px 10px 5px 10px;
                border-radius: 15px;
                background: #FFA253;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: white;
            }
            .label {
                margin: 0px 0px 0px 10px;
                font-weight: normal;
                font-size: 14px;
                line-height: 40px;
                color: #2C2C2C;
            }
            .late-switch {
                margin: 0px 0px 0px 15px;
            }
        }
    }
    .actions {

        .title {
            margin: 20px 0px 13px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #2C2C2C;
        }
        .filters {
            display: flex;
            align-items: center;

            .filter {
                flex-grow: 1;
                margin: 0px 20px 0px 0px;
            }
            .filter:last-child {
                margin: 0px 0px 0px 0px;
            }
            .order-search {
                flex-grow: 1;

                .order-input {
                    width: 100%;
                }
            }
            .resident-search {
                flex-grow: 1;

                .resident-select {
                    width: 100%;

                    .vs__dropdown-option--selected {
                        background-color: rgba(var(--vs-primary), 1);
                    }
                    .vs__dropdown-option--highlight {
                        background-color: rgba(var(--vs-primary), 1);
                    }
                }
            }
            .vendor-search {
                flex-grow: 1;

                .vendor-select {
                    width: 100%;

                    .vs__dropdown-option--selected {
                        background-color: rgba(var(--vs-primary), 1);
                    }
                    .vs__dropdown-option--highlight {
                        background-color: rgba(var(--vs-primary), 1);
                    }
                }
            }
            .date-picker {
                flex-grow: 1;
            }
        }
    }
}
</style>
