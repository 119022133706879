<template>
    <div class="logistic-shipping">
        <IncidentSidebar
            title="Déclarer un incident"
            :active="incidentSidebarActive"
            :products="incidentProducts"
            type="open"
            @close="onIncidentSidebarClose"
            @confirm="onIncidentSidebarConfirm"
        />
        <LogisticFilterBar
            class="filter-bar"
            :filters="filters"
            :lateCount="lateCount"
            @change="onFilterChange"
        />
        <MonthSelector
            class="month-bar"
            :items="monthItems"
            :maxItems="3"
            :selected="monthItemSelected"
            @change="onMonthChange"
            v-if="monthItems.length > 0 && filters.mode == 'received'"
        />

        <div
            class="no-ships"
            v-if="items.length < 1 && this.loaded"
        >
            Aucun résultat ne correspond à votre recherche.
        </div>

        <!--
            *********************************
            Section: Incident
            *********************************
        -->

        <div
            class="ships ships-late"
            v-if="hasItems(true)"
        >
            <div class="title">{{ getModeTitle(true) }}</div>
            <div
                class="ship"
                v-for="(item, itemIndex) in filterItems(true)"
                :key="itemIndex"
            >
                <div class="ship-informations">
                    <div class="ship-header">
                        <div
                            v-if="filters.mode == 'receive'"
                            class="date-legend date-incident"
                        >
                            <img class="incident-icon" src="@/assets/images/ehpad/alert.svg" />
                            <div class="legend-text">{{ getDeliveryLegend(item) }}</div>

                            <img class="help-icon" src="" />
                        </div>
                        <div
                            v-else
                            class="date-legend"
                        >
                            <div class="legend-text">Livraison reçue le {{ item.receivedDate }}</div>
                            <img class="help-icon" src="" />
                        </div>
                        <hx-dropdown
                            v-if="item.canOpenIncident && filters.mode == 'received'"
                            class="incident-dropdown"
                            hx-custom-content
                        >
                            <div class="help-btn">
                                <img class="help-icon" src="@/assets/images/ehpad/icon-aide.svg" />
                            </div>

                            <vs-dropdown-menu
                                class="vx-navbar-dropdown incident-menu"
                            >
                                <div class="incident-content">
                                    <div class="incident-header">
                                        <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                        <div class="incident-title">Déclarer un incident</div>
                                    </div>
                                    <div class="incident-text">
                                        En cliquant sur "Confirmer", la déclaration
                                        d'un incident est <b>transmise directement au
                                        vendeur concerné et au client</b>.
                                    </div>
                                </div>
                            </vs-dropdown-menu>
                        </hx-dropdown>
                    </div>
                    <div class="order-number">N° {{ item.orderId }}</div>
                    <div class="order-resident">{{ item.residentName }}</div>
                    <div class="order-room">Chambre : {{ item.room }}</div>
                    <div class="order-vendor">
                        <img class="vendor-icon" src="" />
                        <div class="column-title">{{ item.shopName }}</div>
                    </div>
                    <div
                        class="order-package"
                        v-if="item.receivedBy || item.statusAutoUpdated"
                    >
                        <div class="package-header">
                            <img class="tick-icon" src="@/assets/images/ehpad/tick.svg" />
                            <div class="package-legend">{{ getReceiptLegend(item) }}</div>
                        </div>
                    </div>
                    <div
                        class="order-opened-incident"
                        v-if="item.hasOpenedIncidents && filters.mode == 'receive'"
                    >
                        <div class="incident-header">
                            <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                            <div class="incident-reason">Incident ouvert - Colis non réceptionné</div>
                        </div>
                        <!--<div class="incident-text">
                            Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua.
                        </div>-->
                    </div>
                    <div
                        class="order-opened-incident"
                        v-if="item.hasOpenedIncidents && filters.mode == 'received'"
                    >
                        <div class="incident-header">
                            <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                            <div class="incident-reason">Incident ouvert</div>
                        </div>
                    </div>
                    <div
                        class="ship-actions"
                        v-if="!item.hasOpenedIncidents && filters.mode == 'receive'"
                    >
                        <div class="action-legend">
                            <div class="legend-title">Avez-vous réceptionné ce colis ?</div>
                        </div>
                        <div class="action">
                            <vs-button
                                class="received-btn"
                                @click="onReveivedBtnClick(item)"
                            >Oui, colis reçu</vs-button>
                        </div>
                        <div
                            class="action"
                            v-if="item.missedBtnVisible"
                        >
                            <vs-button
                                class="missed-btn"
                                type="border"
                                @click="onMissedBtnClick(item)"
                            >Non, colis absent</vs-button>
                        </div>
                    </div>
                    <div
                        class="ship-actions"
                        v-if="item.canOpenIncident && filters.mode == 'received'"
                    >
                        <div class="action-legend">
                            <div class="legend-title">
                                Rencontrez-vous un problème <br />
                                avec cette commande ?
                            </div>
                        </div>
                        <div class="action">
                            <vs-button
                                class="incident-btn"
                                @click="onIncidentBtnClick(item)"
                            >Déclarer un incident</vs-button>
                        </div>
                    </div>
                    <div class="d-flex justify-center mt-4" v-if="item.shippingTrackingUrl">
                        <HBtn
                        data-cy="discover-btn"
                        :href="item.shippingTrackingUrl"
                        target="_blank"
                        style-type="tertiary"
                        icon="TruckDeliveryIcon"
                        >Suivi du colis</HBtn>
                    </div>
                </div>
                <div class="ship-content">
                    <div class="columns">
                        <div class="column-title">Désignation du produit</div>
                        <div class="column-quantity">Quantité commandée</div>
                    </div>
                    <div class="ship-products">
                        <div
                            class="product"
                            v-for="(product, productIndex) in item.products"
                            :key="productIndex"
                        >
                            <hx-dropdown
                                class="incident-dropdown"
                                hx-custom-content
                                v-if="product.incident"
                            >
                                <div class="alert-btn">
                                    <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                </div>

                                <vs-dropdown-menu
                                    class="vx-navbar-dropdown incident-menu"
                                >
                                    <div class="incident-content">
                                        <div class="incident-header">
                                            <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                            <div class="incident-title">Incident créé</div>
                                        </div>
                                        <div class="incident-reason">{{ product.incident.label }}</div>
                                        <div class="incident-text">
                                            {{ getProductIncidentLegend(product.incident) }}
                                        </div>
                                    </div>
                                </vs-dropdown-menu>
                            </hx-dropdown>

                            <img class="product-photo" :src="product.photo" />
                            <div
                              class="product-title"
                              :class="getProductTitleClass(product)"
                            >{{ getProductTitle(product) }}</div>
                            <div class="product-quantity">
                                <div class="quantity-bullet">{{ product.quantity }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--
            *********************************
            Section: Normal
            *********************************
        -->

        <div
            class="ships"
            v-if="hasItems(false)"
        >
            <div class="title">{{ getModeTitle(false) }}</div>
            <div
                class="ship"
                v-for="(item, itemIndex) in filterItems(false)"
                :key="itemIndex"
            >
                <div class="ship-informations">
                    <div class="ship-header">
                        <div
                            v-if="filters.mode == 'receive'"
                            class="date-legend"
                        >
                            <div class="legend-text">{{ getDeliveryLegend(item) }}</div>
                            <img class="help-icon" src="" />
                        </div>
                        <div
                            v-else
                            class="date-legend"
                        >
                            <div class="legend-text">Livraison reçue le {{ item.receivedDate }}</div>
                            <img class="help-icon" src="" />
                        </div>
                        <hx-dropdown
                            v-if="item.canOpenIncident && filters.mode == 'received'"
                            class="incident-dropdown"
                            hx-custom-content
                        >
                            <div class="help-btn">
                                <img class="help-icon" src="@/assets/images/ehpad/icon-aide.svg" />
                            </div>

                            <vs-dropdown-menu
                                class="vx-navbar-dropdown incident-menu"
                            >
                                <div class="incident-content">
                                    <div class="incident-header">
                                        <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                        <div class="incident-title">Déclarer un incident</div>
                                    </div>
                                    <div class="incident-text">
                                        En cliquant sur "Confirmer", la déclaration
                                        d'un incident est <b>transmise directement au
                                        vendeur concerné et au client</b>.
                                    </div>
                                </div>
                            </vs-dropdown-menu>
                        </hx-dropdown>
                    </div>
                    <div class="order-number">N° {{ item.orderId }}</div>
                    <div class="order-resident">{{ item.residentName }}</div>
                    <div class="order-room">Chambre : {{ item.room }}</div>
                    <div class="order-vendor">
                        <img class="vendor-icon" src="" />
                        <div class="column-title">{{ item.shopName }}</div>
                    </div>
                    <div
                        class="order-package"
                        v-if="item.receivedBy || item.statusAutoUpdated"
                    >
                        <div class="package-header">
                            <img class="tick-icon" src="@/assets/images/ehpad/tick.svg" />
                            <div class="package-legend">{{ getReceiptLegend(item) }}</div>
                        </div>
                    </div>
                    <div
                        class="order-closed-incident"
                        v-if="item.lastClosedIncident && filters.mode == 'received'"
                    >
                        <div class="incident-header">
                            <div class="incident-reason">Incident clôturé</div>
                        </div>
                        <div class="incident-text">{{ getClosedIncidentText(item) }}</div>
                    </div>
                    <div
                        class="ship-actions"
                        v-if="filters.mode == 'receive'"
                    >
                        <div class="action-legend">
                            <div class="legend-title">Avez-vous réceptionné ce colis ?</div>
                        </div>
                        <div class="action">
                            <vs-button
                                class="received-btn"
                                @click="onReveivedBtnClick(item)"
                            >Oui, colis reçu</vs-button>
                        </div>
                    </div>
                    <div
                        class="ship-actions"
                        v-if="item.canOpenIncident && filters.mode == 'received'"
                    >
                        <div class="action-legend">
                            <div class="legend-title">Rencontrez-vous un problème avec cette commande ?</div>
                        </div>
                        <div class="action">
                            <vs-button
                                class="incident-btn"
                                @click="onIncidentBtnClick(item)"
                            >Déclarer un incident</vs-button>
                        </div>
                    </div>
                    <div class="d-flex justify-center mt-4" v-if="item.shippingTrackingUrl">
                        <HBtn
                        data-cy="discover-btn"
                        :href="item.shippingTrackingUrl"
                        target="_blank"
                        style-type="tertiary"
                        icon="TruckDeliveryIcon"
                        >Suivi du colis</HBtn>
                    </div>
                </div>
                <div class="ship-content">
                    <div class="columns">
                        <div class="column-title">Désignation du produit</div>
                        <div class="column-quantity">Quantité commandée</div>
                    </div>
                    <div class="ship-products">
                        <div
                            class="product"
                            v-for="(product, productIndex) in item.products"
                            :key="productIndex"
                        >
                            <img class="product-photo" :src="product.photo" />
                            <div
                              class="product-title"
                              :class="getProductTitleClass(product)"
                            >{{ getProductTitle(product) }}</div>
                            <div class="product-quantity">
                                <div class="quantity-bullet">{{ product.quantity }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import Http from '@/http'

import Formats from '@/constants/formats'
import OrderStatus from '@/constants/order-status'
import ProductStatus from '@/constants/product-status'

import {
    getUtcNow,
    getTimestamp,
    getDateFromString,
    formatLocalDate
} from '@/modules/date'
import {
    getMonthLabel,
    getResidentNameLabel
} from '@/modules/labels'
import {
    getRequestUrl
} from '@/modules/url'

import IncidentSidebar from '@/components/IncidentSidebar'
import LogisticFilterBar from '@/components/LogisticFilterBar'
import MonthSelector from '@/components/selectors/MonthSelector'
import {HBtn} from '@happytal/bo-ui-library'

export default {
    name: 'LogisticShipping',
    components: {
        IncidentSidebar,
        LogisticFilterBar,
        MonthSelector,
        HBtn
    },
    data () {
        return {
            loaded: false,
            incidentSidebarActive: false,
            filters: {
                mode: 'receive',
                late: false
            },
            items: [],
            hasOpenedIncidents: false,
            hasLateDeliveries: false,
            lateCount: 0,
            monthItems: [],
            monthItemSelected: null,
            incidentProducts: []
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getProductTitle (product) {
          let title = product.title
          if (product.status == ProductStatus.REFUSED) {
            title += ` (produit refusé)`
          }
          return title
        },
        getProductTitleClass (product) {
          return product.status == ProductStatus.REFUSED ? 'product-title-refused' : ''
        },
        getModeTitle (late) {
            switch (this.filters.mode) {
                case 'receive':
                    if (late) {
                        return `Livraisons en retard`
                    }
                    else {
                        return `Livraisons à venir`
                    }
                case 'received':
                    if (late) {
                        return `Incidents ouverts`
                    }
                    else {
                        return `Livraisons réceptionnées`
                    }
            }
        },
        getDeliveryLegend (item) {
            const {
                earliest,
                latest
            } = item.deliveryDates
            if (earliest == latest) {
                return `Livraison prévue le ${earliest}`
            }
            return `Livraison prévue entre le ${earliest} et le ${latest}`
        },
        getReceiptLegend (item) {
            if (item.statusAutoUpdated) {
                return `Colis réceptionné - mise à jour automatique`
            }
            return `Colis réceptionné par ${item.receivedBy}`
        },
        getClosedIncidentText (item) {
            const incident = item.lastClosedIncident
            const date = formatLocalDate(incident.closedOnUtc, Formats.LogisticDate)
            //const vendor = _.get(incident, 'closedBy', '-')
            return `Le ${date}`// par ${vendor}`
        },
        getProductIncidentLegend (incident) {
            const date = formatLocalDate(incident.openedOnUtc, Formats.LogisticDate)
            const firstName = _.get(incident, 'openedBy.firstName', '-')
            const lastName = _.get(incident, 'openedBy.lastName', '-')
            const name = `${firstName} ${lastName}`
            return `Le ${date} par ${name}`
        },
        getMonthItem (month, year) {
            const monthLabel = getMonthLabel(month)
            const label = `${monthLabel} ${year}`
            return {
                month,
                year,
                label
            }
        },
        isMonthItemExists (items, month, year) {
            return _.find(items, (item) => {
                return item.month == month && item.year == year
            })
        },
        getMonthItemsFromPeriod (period) {
            var items = []
            var currentDate = moment(period.dateStart)
            currentDate.hour(0)
            currentDate.minute(0)
            currentDate.second(0)
            var endDate = moment(period.dateEnd)
            endDate.hour(23)
            endDate.minute(59)
            endDate.second(59)
            while (endDate > currentDate || currentDate.format('M') === endDate.format('M')) {
                const year = currentDate.year()
                const month = currentDate.month()
                items.push(this.getMonthItem(month, year))
                currentDate.add(1, 'month')
            }
            return items
        },
        onFilterChange (params) {
            const today = moment()
            const todayMonth = today.month()
            const todayYear = today.year()
            this.filters = params.filters
            switch (params.name) {
                case 'period':
                    if (this.filters.period.dateStart && this.filters.period.dateEnd) {
                        this.monthItems = this.getMonthItemsFromPeriod(this.filters.period)
                        this.monthItemSelected = _.find(this.monthItems, (item) => {
                            return item.month == todayMonth && item.year == todayYear
                        })
                        if (!this.monthItemSelected) {
                            this.monthItemSelected = this.monthItems[0]
                        }
                    }
                    else {
                        this.monthItems = []
                        this.monthItems.push(this.getMonthItem(todayMonth, todayYear))
                        this.monthItemSelected = this.monthItems[0]
                    }
                    break
                case 'mode':
                    if (this.filters.mode == 'received') {
                        this.monthItems = []
                        this.monthItems.push(this.getMonthItem(todayMonth, todayYear))
                        this.monthItemSelected = this.monthItems[0]
                    }
                    break
            }
            this.searchOrders()
        },
        onReveivedBtnClick (item) {
            this.markAsReceived(item.orderId)
        },
        markAsReceived (orderId) {
            Http.apis.ehpad.put(`/order-logistic-products/${orderId}/validate-receipt`, {
                userId: this.getUserId()
            })
            .then((response) => {
                this.searchOrders()
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        onMissedBtnClick (item) {
            this.markAsMissed(item)
        },
        markAsMissed (item) {
            // Send reason code "1" (Article non reçu) for all products
            const incidents = _.map(item.products, (product) => {
                return {
                    orderLineId: product.orderLineId,
                    reasonCode: "1"
                }
            })
            const orderId = item.orderId
            Http.apis.ehpad.post(`/order-logistic-products/${orderId}/incidents`, {
                userId: this.getUserId(),
                incidents
            })
            .then((response) => {
                this.searchOrders()
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        onMonthChange (item) {
            this.monthItemSelected = item
            this.searchOrders()
        },
        showIncidentSideBar (visible) {
            this.incidentSidebarActive = visible
        },
        onIncidentBtnClick (item) {
            this.itemSelected = item
            const products = _.filter(item.products, (product) => {
                return product.sidebarVisible
            })
            this.incidentProducts = _.map(products, (product) => {
                return {
                    orderLineId: product.orderLineId,
                    title: product.title,
                    photo: product.photo,
                    incident: product.incident,
                    reason: 0,
                }
            })
            this.showIncidentSideBar(true)
        },
        onIncidentSidebarClose () {
            this.showIncidentSideBar(false)
        },
        onIncidentSidebarConfirm (products) {
            this.showIncidentSideBar(false)
            const incidentProducts = _.filter(products, (product) => {
                return product.reason != "0" && !product.incident
            })
            if (!incidentProducts.length) {
                return
            }
            const incidents = _.map(incidentProducts, (product) => {
                return {
                    orderLineId: product.orderLineId,
                    reasonCode: product.reason
                }
            })
            const orderId = this.itemSelected.orderId
            Http.apis.ehpad.post(`/order-logistic-products/${orderId}/incidents`, {
                userId: this.getUserId(),
                incidents
            })
            .then((response) => {
                this.searchOrders()
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        hasItems (late) {
            return this.filterItems(late).length > 0
        },
        filterItems (late) {
            const items = _.filter(this.items, (item) => {
                return late == item.late
            })
            const mode = this.filters.mode || null
            const isReceived = mode ? mode == 'received' : false
            if (isReceived) {
                return _.orderBy(items, [
                        (item) => _.get(item, 'timestamps.received', 1),
                        (item) => _.get(item, 'timestamps.created', 1)
                    ],
                    ['desc', 'desc']
                )
            }
            else {
                return _.orderBy(items, [
                        (item) => _.get(item, 'timestamps.earliest', 1),
                        (item) => _.get(item, 'timestamps.latest', 1),
                        (item) => _.get(item, 'timestamps.created', 1)
                    ],
                    ['asc', 'asc', 'asc']
                )
            }
        },
        getItemFromResults (results) {
            const today = moment()
            this.lateCount = 0
            const mode = this.filters.mode || null
            const isReceived = mode ? mode == 'received' : false
            return _.map(results, (result) => {
                const deliveryEarliestDateString = _.get(result, 'order.orderContent.deliveryDate.earliest', null)
                const deliveryLatestDateString = _.get(result, 'order.orderContent.deliveryDate.latest', null)
                const createdDateString = _.get(result, 'order.orderContent.createdDate', null)
                const createdDate = getDateFromString(createdDateString)
                const deliveryEarliestDate = getDateFromString(deliveryEarliestDateString)
                deliveryEarliestDate.hour(0)
                deliveryEarliestDate.minute(0)
                deliveryEarliestDate.second(0)
                // Hide missed button +3 days after erliest estimated delivery date
                const today = moment()
                const missedDateLimit = moment(deliveryEarliestDate).add('days', 3)
                const missedBtnVisible = today.isAfter(missedDateLimit)
                const deliveryLatestDate = getDateFromString(deliveryLatestDateString)
                deliveryLatestDate.hour(23)
                deliveryLatestDate.minute(59)
                deliveryLatestDate.second(59)
                const resident = _.get(result, 'resident', null)
                const receivedBy = _.get(result, 'order.receivedBy', null)
                const receivedFirstName = _.get(receivedBy, 'firstName', '-')
                const receivedLastName = _.get(receivedBy, 'lastName', '-')
                const receivedName = receivedBy ? `${receivedFirstName} ${receivedLastName}` : null
                const incidents = _.get(result, 'order.incidents', [])
                //const hasOpenedIncidents = _.get(result, 'order.orderContent.hasIncident', false)
                const activeIncidents = _.filter(incidents, (incident) => {
                    return !incident.closedOnUtc
                })
                const hasOpenedIncidents = activeIncidents.length > 0
                var closedIncidents = _.filter(incidents, (incident) => {
                    return incident.closedOnUtc
                })
                closedIncidents = _.sortBy(closedIncidents, (incident) => {
                    const closedDate = getDateFromString(incident.closedOnUtc)
                    return -getTimestamp(closedDate)
                })
                const hasClosedIncidents = closedIncidents.length > 0
                var allProductHaveIncident = true
                const orderState = _.get(result, 'order.orderContent.orderState', null)
                var item = {
                    orderId: _.get(result, 'order.orderContent.orderId', '-'),
                    deliveryDates: {
                        earliest: deliveryEarliestDate.format(Formats.SlashDate),
                        latest: deliveryLatestDate.format(Formats.SlashDate)
                    },
                    timestamps: {
                        created: getTimestamp(createdDate),
                        earliest: getTimestamp(deliveryEarliestDate),
                        latest: getTimestamp(deliveryLatestDate)
                    },
                    late: isReceived ? hasOpenedIncidents : today.isAfter(deliveryLatestDate),
                    residentName: getResidentNameLabel(resident),
                    room: _.get(result, 'resident.roomNumber', '-'),
                    shopName: _.get(result, 'order.orderContent.shopName', '-'),
                    orderState,
                    receivedBy: receivedName,
                    statusAutoUpdated: receivedBy == null && orderState == OrderStatus.RECEIVED,
                    hasOpenedIncidents,
                    hasClosedIncidents,
                    missedBtnVisible,
                    shippingTrackingUrl: _.get(result, 'order.orderContent.shippingTrackingUrl', null)
                }

                if (item.late) {
                    this.lateCount++
                }
                const orderLines = _.get(result, 'order.orderContent.orderLines', [])
                item.products = _.map(orderLines, (orderLine) => {
                    const status = _.get(orderLine, 'orderLineState', null)
                    const orderLineId = _.get(orderLine, 'orderLineId', null)
                    // Search for any product without any incident (opened and closed)
                    if (allProductHaveIncident) {
                        const productIncident = _.find(incidents, (incident) => {
                            return orderLineId == incident.orderLineId
                        })
                        if (!productIncident) {
                            allProductHaveIncident = false
                        }
                    }
                    // Find product active incidents
                    const incident = _.find(activeIncidents, (incident) => {
                        return orderLineId == incident.orderLineId
                    })
                    // A. Check if product is visible for incident sidebar
                    let sidebarVisible = true
                    // A-1. Exclude refused products
                    if (status == ProductStatus.REFUSED) {
                        sidebarVisible = false
                    }
                    // A-2. Exclude product with incident closed
                    if (_.find(closedIncidents, (incident) => {
                        return orderLineId == incident.orderLineId
                    })) {
                        sidebarVisible = false
                    }

                    var product = {
                        orderLineId,
                        photo: _.get(orderLine, 'productMedias[0].mediaUrl', null),
                        title: _.get(orderLine, 'productTitle', '-'),
                        status,
                        quantity: _.get(orderLine, 'quantity', '-'),
                        incident,
                        sidebarVisible
                    }
                    return product
                })
                // Pick first valid received date
                const validOrderLine = _.find(orderLines, (orderLine) => {
                    return orderLine.receivedDate
                })
                const receivedDateString = _.get(validOrderLine, 'receivedDate', null)
                if (receivedDateString) {
                    const receivedDate = getDateFromString(receivedDateString)
                    item.receivedDate = receivedDate.format(Formats.SlashDate)
                    item.timestamps.received = getTimestamp(item.receivedDate)
                }
                else {
                    item.receivedDate = '-'
                    item.timestamps.received = 0
                }

                // Scan all products to find latest closed incident
                const searchProducts = _.filter(item.products, (product) => {
                    // Exclude all products with incident closed
                    if (_.find(closedIncidents, (incident) => {
                        return product.orderLineId == incident.orderLineId
                    })) {
                        return false
                    }
                    // Exclude all products with REFUSED status
                    if (product.status == ProductStatus.REFUSED) {
                        return false
                    }
                    return true
                })
                item.lastClosedIncident = searchProducts.length <= 0 ? closedIncidents[0] : null

                // B. Check if user can open incident
                let canOpenIncident = true
                // B-1. Disabled if order closed
                if (item.orderState == OrderStatus.CLOSED) {
                    canOpenIncident = false
                }
                // B-2. Disabled if all product have incident declared
                if (allProductHaveIncident) {
                    canOpenIncident = false
                }
                // B-3. Disabled if all product not visible on sidebar
                if (!_.find(item.products, (product) => {
                    return product.sidebarVisible
                })) {
                    canOpenIncident = false
                }
                item.canOpenIncident = canOpenIncident
                item.products = _.sortBy(item.products, (product) => {
                    return product.status == ProductStatus.REFUSED ? 2 : 1
                })
                return item
            })
        },
        getIntersectedPeriod (dateStart, dateEnd, month, year) {
            const pickerStart = dateStart ? moment(dateStart) : null
            if (pickerStart) {
                pickerStart.hour(0)
                pickerStart.minute(0)
                pickerStart.second(0)
            }
            const pickerEnd = dateEnd ? moment(dateEnd) : null
            if (pickerEnd) {
                pickerEnd.hour(23)
                pickerEnd.minute(59)
                pickerEnd.second(59)
            }
            var monthStart = moment({
                year,
                month,
                day: 1
            })
            var monthEnd = moment(monthStart).endOf('month')
            var resultStart = monthStart
            var resultEnd = monthEnd
            if (pickerStart &&
                pickerStart.isAfter(monthStart) &&
                pickerStart.isBefore(monthEnd)) {
                resultStart = pickerStart
            }
            if (pickerEnd &&
                pickerEnd.isAfter(monthStart) &&
                pickerEnd.isBefore(monthEnd)) {
                resultEnd = pickerEnd
            }
            return {
                start: resultStart.format(Formats.RequestDate),
                end: resultEnd.format(Formats.RequestDate)
            }
        },
        searchOrders () {
            this.locked = true
            this.loaded = false
            this.$vs.loading()
            this.items = []
            var params = {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
            }
            if (this.filters.order) {
                params.orderId = this.filters.order
            }
            if (this.filters.resident) {
                params.residentId = this.filters.resident
            }
            if (this.filters.vendor) {
                params.shopId = this.filters.vendor
            }
            const period = this.filters.period || {}
            const dateStart = period.dateStart ? moment(period.dateStart) : null
            const dateEnd = period.dateEnd ? moment(period.dateEnd) : null
            const mode = this.filters.mode || null
            const isReceived = mode ? mode == 'received' : false
            if (isReceived) {
                var month = 0
                var year = 0
                if (this.monthItemSelected) {
                    month = this.monthItemSelected.month
                    year = this.monthItemSelected.year
                }
                else {
                    const today = moment()
                    month = today.month()
                    year = today.year()
                }
                const finalPeriod = this.getIntersectedPeriod(
                    period.dateStart,
                    period.dateEnd,
                    month,
                    year
                )
                params.receivedFrom = finalPeriod.start
                params.receivedTo = finalPeriod.end
                if (!_.isNil(this.filters.late)) {
                    params.includeIncidents = this.filters.late
                }
            }
            else {
                params.includeIncidents = true
                if (dateStart) {
                    dateStart.hour(0)
                    dateStart.minute(0)
                    dateStart.second(0)
                    params.expectedFrom = dateStart.format(Formats.RequestDate)
                }
                if (dateEnd) {
                    dateEnd.hour(23)
                    dateEnd.minute(59)
                    dateEnd.second(59)
                    params.expectedTo = dateEnd.format(Formats.RequestDate)
                }
                if (!_.isNil(this.filters.late)) {
                    params.includeLateDeliveries = this.filters.late
                }
            }
            params.isReceived = isReceived
            //console.log('params', params)
            const requestUrl = getRequestUrl(params)
            //console.log('requestUrl', requestUrl)
            Http.apis.ehpad.get(`/order-logistic-products${requestUrl}`).then((response) => {
                const data = response.data || {}
                const results = data.results || []
                this.items = this.getItemFromResults(results)
                //console.log('items', this.items)
                this.loaded = true
                this.locked = false
                this.$vs.loading.close()
            })
            .catch((error) => {
                this.$vs.loading.close()
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: 5000,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: null,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.searchOrders()
    }
}
</script>

<style lang="scss">
.logistic-shipping-view {
    padding: 30px 0px 30px 0px;
}

.incident-menu {
    width: 292px;

    .incident-content {
        padding: 25px 25px 25px 25px;
        color: #2C2C2C;

        .incident-header {
            display: flex;
            align-items: center;

            .alert-icon {

            }
            .incident-title {
                flex-grow: 1;
                margin: 0px 0px 0px 10px;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .incident-reason {
            margin: 5px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #FFA253;
        }
        .incident-text {
            margin: 10px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.logistic-shipping {

    .filter-bar {
        margin: 0px 30px 31px 30px;
    }
    .month-bar {
        margin: 0px 30px 25px 30px;
    }
    .incident-dropdown {
        cursor: pointer;

        .alert-btn {
            margin: 0px 23px 0px 0px;
        }
        .alert-icon {

        }
    }

    .no-ships {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #2C2C2C;
    }
    .ships-late {
        background: rgba(255, 162, 83, 0.2);

        .ship-content {
            background: #FFFFFF;
        }
    }
    .ships {
        padding: 29px 0px 55px 0px;

        .trackingLink {
            margin-top:30px;
        }
        .title {
            margin: 0px 0px 0px 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .ship {
            display: flex;
            margin: 32px 30px 0px 30px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
            border-radius: 8px;

            .ship-informations {
                flex-shrink: 0;
                position: relative;
                width: 420px;
                padding: 15px 15px 15px 15px;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.170509);
                border-radius: 8px 0px 0px 8px;

                .ship-header {
                    display: flex;
                    align-items: center;
                }

                .date-incident {

                    .incident-icon {
                        display: block !important;
                    }
                    .legend-text {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #FFA253;
                    }
                }
                .date-legend {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    margin: 0px 0px 10px 0px;

                    .incident-icon {
                        margin: 0px 15px 0px 0px;
                        display: none;
                    }
                    .legend-text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        color: #2C2C2C;
                    }
                    .help-icon {

                    }
                }
                .order-number {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    color: #2C2C2C;
                }
                .order-resident {
                    margin: 12px 0px 0px 0px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 19px;
                    color: #2C2C2C;
                }
                .order-room {
                    margin: 10px 0px 0px 0px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 40px;
                    color: #2C2C2C;
                }
                .order-vendor {
                    display: flex;
                    align-items: center;

                    .vendor-icon {
                        width: 32px;
                    }
                    .column-title {
                        margin: 0px 0px 0px 8px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        color: #2C2C2C;
                    }
                }
                .order-opened-incident {
                    margin: 15px 0px 0px 0px;

                    .incident-header {
                        display: flex;
                        align-items: center;

                        .alert-icon {

                        }
                        .incident-reason {
                            margin: 0px 0px 0px 10px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 19px;
                            color: #FFA253;
                        }
                    }
                    .incident-text {
                        margin: 5px 0px 0px 0px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                .order-closed-incident {
                    margin: 15px 0px 0px 0px;

                    .incident-header {
                        display: flex;
                        align-items: center;

                        .incident-reason {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 19px;
                            color: rgba(var(--vs-primary), 1);
                        }
                    }
                    .incident-text {
                        margin: 5px 0px 0px 0px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                .order-package {
                    margin: 15px 0px 0px 0px;

                    .package-header {
                        display: flex;
                        align-items: center;

                        .tick-icon {

                        }
                        .package-legend {
                            margin: 0px 0px 0px 10px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            color: rgba(var(--vs-primary), 1);
                        }
                    }
                }
                .ship-actions {

                    .action-legend {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .legend-title {
                            margin: 7px 0px 0px 0px;
                            text-align: center;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            color: #2C2C2C;
                        }
                    }
                    .action {
                        text-align: center;

                        .received-btn {
                            width: 273px;
                            margin: 12px 0px 0px 0px;
                        }
                        .missed-btn {
                            width: 273px;
                            margin: 19px 0px 0px 0px;
                        }
                        .incident-btn {
                            width: 273px;
                            margin: 19px 0px 0px 0px;
                        }
                    }
                }
            }
            .ship-content {
                flex-grow: 1;
                padding: 0px 0px 0px 30px;
                border-radius: 0px 8px 8px 0px;

                .columns {
                    display: flex;
                    margin: 17px 0px 17px 0px;

                    .column-title {
                        flex-grow: 1;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #2C2C2C;
                    }
                    .column-quantity {
                        width: 200px;
                        margin: 0px 18px 0px 0px;
                        text-align: center;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #2C2C2C;
                    }
                }
                .ship-products {
                    height: 300px;
                    overflow-y: auto;

                    .product:first-child {
                        padding: 0px 0px 17px 0px;
                    }
                    .product {
                        display: flex;
                        align-items: center;
                        padding: 17px 0px 17px 0px;
                        border-bottom: 1px solid #D8D8D8;

                        .product-photo {
                            width: 32px;
                            height: 32px;
                            background: #A5A5A5;
                        }
                        .product-title {
                            flex-grow: 1;
                            margin: 0px 0px 0px 16px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            color: #2C2C2C;
                        }
                        .product-title-refused {
                            color: #E03A3E;
                        }
                        .product-quantity {
                            width: 200px;
                            flex-shrink: 0;
                            text-align: center;

                            .quantity-bullet {
                                display: inline-block;
                                padding: 4px 27px 4px 27px;
                                border-radius: 3px;
                                background: #BDBDBD;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 17px;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
