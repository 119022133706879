<template>
    <div class="month-selector">
        <div class="month-toolbar">
            <vs-button
                class="previous-btn"
                color="primary"
                icon="chevron_left"
                @click="onPreviousBtnClick"
                v-if="!disabled && !isPreviousBtnDisabled()"
            />
            <vs-button
                class="item-btn"
                color="primary"
                :type="getItemType(item)"
                @click="onItemBtnClick(item)"
                v-for="(item, itemIndex) in items"
                :key="itemIndex"
                :disabled="disabled"
            >{{ item.label }}</vs-button>
            <vs-button
                class="next-btn"
                color="primary"
                icon="chevron_right"
                @click="onNextBtnClick"
                v-if="!disabled && !isNextBtnDisabled()"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import {
    getTimestamp,
    getDateFromTimestamp
} from '@/modules/date'

export default {
    name: 'MonthSelector',
    props: {
        selected: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        },
        maxItems: {
            type: Number,
            default: 3
        }
    },
    methods: {
        isItemSelected (item) {
            if (!this.selected || !item) {
                return false
            }
            if (this.selected.month == item.month &&
                this.selected.year == item.year) {
                return true
            }
            return false
        },
        getItemSelectedIndex () {
            return _.findIndex(this.items, (item) => {
                return this.isItemSelected(item)
            })
        },
        getItemType (item) {
            if (this.isItemSelected(item)) {
                return 'filled'
            }
            else {
                return 'flat'
            }
        },
        isPreviousBtnDisabled () {
            return this.isItemSelected(this.items[0])
        },
        onPreviousBtnClick (e) {
            const index = this.getItemSelectedIndex()
            this.sendChange(this.items[index - 1])
        },
        isNextBtnDisabled () {
            return this.isItemSelected(this.items[this.items.length - 1])
        },
        onNextBtnClick (e) {
            const index = this.getItemSelectedIndex()
            this.sendChange(this.items[index + 1])
        },
        onItemBtnClick (item) {
            this.sendChange(item)
        },
        sendChange (item) {
            this.$emit('change', item)
        }
    }
}
</script>

<style lang="scss">
.month-selector {

    .month-toolbar {
        display: flex;
        margin: 10px auto 10px auto;

        .previous-btn {
            margin: 0px 10px 0px 0px;

            .vs-button--icon {
                font-size: 32px !important;
            }
        }
        .item-btn {

        }
        .next-btn {
            margin: 0px 0px 0px 10px;

            .vs-button--icon {
                font-size: 32px !important;
            }
        }
    }
}
</style>
